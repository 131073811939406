const themes = [
    {
        color: 'white',
        themes: new URL('../assets/theme1.png', import.meta.url),
        fontColor1: 'rgba(51, 92, 255, 1)',
        fontColor2: 'rgba(236, 93, 33, 1)',
        fontColor3: '#99A0AE',
        Background: 'white'
    },
    {
        color: 'orange',
        themes: new URL('../assets/theme2.png', import.meta.url),
        fontColor1: 'rgba(51, 92, 255, 1)',
        fontColor2: 'rgba(51, 92, 255, 1)',
        fontColor3: 'rgba(51, 92, 255, 1)',
        Background: ''
    },
    {
        color: 'purple',
        themes: new URL('../assets/theme3.png', import.meta.url),
        fontColor1: 'rgba(255, 255, 255, 1)',
        fontColor2: 'rgba(255, 255, 255, 1)',
        fontColor3: 'rgba(255, 255, 255, 1)',
        Background: ''
    },
    {
        color: 'image',
        fontColor1: 'rgba(0, 0, 0, 1)',
        fontColor2: 'rgba(0, 0, 0, 1)',
        fontColor3: 'rgba(0, 0, 0, 1)',
        Background: 'rgba(255, 255, 255, 0.6)'
    },
]

export default themes;