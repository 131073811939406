/* eslint-disable */
import {
    defineStore
} from 'pinia';
import {
    reactive,
    ref,
    toRefs
} from 'vue';

export const usePlaylistStore = defineStore('playlist', {
    state: function() {
        var state = reactive({
            playlist: {
                name: '',
                time: '',
                slides: []
            }
        });
        return toRefs(state);
    },
    actions: {
        changeName: function(text) {
            this.playlist.name.value = text;
        },
        changeTime: function(time) {
            this.playlist.time.value = time;
        },
        addSlide: function(slideObject, currentSlideNo) {
            slideObject.id = currentSlideNo;
            var newSlides = this.playlist.slides.value.slice();
            newSlides.push(slideObject);
            this.playlist.slides.value = newSlides;
        },
        updateElement: function(id, file) {
            var slides = this.playlist.slides.value.slice();
            for (var i = 0; i < slides.length; i++) {
                if (slides[i].id === id) {
                    slides[i].file = file;
                    break;
                }
            }
            this.playlist.slides.value = slides;
        }
    },
    getters: {
        getPlaylist: function() {
            return this.playlist;
        },
        getSlideById: function() {
            var self = this;
            return function(id) {
                var slides = self.playlist.slides.value;
                for (var i = 0; i < slides.length; i++) {
                    if (slides[i].id === id) {
                        return slides[i];
                    }
                }
                return null;
            };
        },
        getPlaylistLength: function() {
            return this.playlist.slides.value.length;
        }
    }
});
