/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router';
import HelloWorld from '@/components/HelloWorld.vue';
import AboutPage from '@/components/AboutPage.vue';
import SlidePlayer from '@/views/SlidePlayer.vue';
import ActivationCode from '@/views/ActivationCode.vue';
import ScreenConnected from '@/views/ScreenConnected.vue';
const routes = [
  {
    path: '/',
    name: 'ActivationCode',
    component: ActivationCode,
  },
  {
    path: '/hello',
    name: 'HelloWorld',
    component: HelloWorld,
  },
  {
    path: '/connected',
    name: 'Noise',
    component: ScreenConnected,
  },
  {
    path: '/slide',
    name: 'SlidePlayer',
    component: SlidePlayer,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
