<script setup>
  import mixpanel from "mixpanel-browser";
  import { onMounted } from 'vue';

  onMounted(() => {
    mixpanel.init("df1ff14c11481629aa323409e67174a9",{
      ignore_dnt: true,
      track_pageview: true,
      persistence: "localStorage",
    })
  });
</script>
<template>
  <div class="main">
    <router-view></router-view>
  </div>
</template>
 
<script>

export default {
  name: 'App',
}
</script>

<style>
.main {
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
</style>
