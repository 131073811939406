const size = [
    {
        size: 'xs',
        title: '2.94vw',
        subtitle: '1.72vw',
        lineHeight: '3.28vw',
        sub_lineHeight: '2.25vw'
    },
    {
        size: 's',
        title: '3.43vw',
        subtitle: '1.84vw',
        lineHeight: '3.80vw',
        sub_lineHeight: '2.43vw'
    },
    {
        size: 'm',
        title: '4.17vw',
        subtitle: '2.08vw',
        lineHeight: '4.66vw',
        sub_lineHeight: '2.75vw'
    },
    {
        size: 'l',
        title: '5.15vw',
        subtitle: '2.21vw',
        lineHeight: '5.76vw',
        sub_lineHeight: '2.94vw'
    }
]
export default size;