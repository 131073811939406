<template>
    <div class="noise-container">
        <div class="inner-box">
            <div class="logo">
                <img src="../assets/Apex.svg" alt="">
                <p>Petti</p>
            </div>
            <div class="sub-title">
                <p>No Playlist Yet? Let’s Get Started!</p>
            </div>
            <div class="sub-text">
                <p>Head over to your Petti dashboard to create or connect a playlist.</p>
            </div>
        </div>
    </div>
</template>
<style scoped>
.noise-container{
    width: 100%;
    height: 100vh;
    background: url('../assets/noise.png');
    background-size: contain;
    background-repeat: repeat;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.noise-container .inner-box{
    width: 690px;
    height: 320px;
    border-radius: 50px;
    padding: 40px 32px;
    background-color: rgba(14, 18, 27, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.logo{
    display: flex;
    margin-bottom: 24px;

}
.logo p{
    font-family: "Inter";
    font-style: italic;
    font-weight: 600;
    font-size: 40px;
    padding: 0px 12px;
    line-height: 40px;
    letter-spacing: -0.005em;
    display: flex;
}
.inner-box .sub-title{
    font-family: "Inter";
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 8px;
}
.inner-box .sub-text{
    font-family: "Inter";
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
}
</style>